import {
  WILLS_TELEPHONE_NUMBER,
  TELEPHONE_WILLS_TELEPHONE_NUMBER,
  PROBATE_TELEPHONE_NUMBER,
  CREMATION_TELEPHONE_NUMBER,
  PROBATE_SIMPLE_FEE,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
  FUNERALS_SUPPORT_EMAIL,
  FUNERAL_PLAN_TELEPHONE_NUMBER,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import { VIEWS_FOLDER, VIEWS_FOLDER_KEYS } from 'lib/routing/constants'

export enum PRODUCTS {
  WILLS = 'WILLS',
  TELEPHONE_WILLS = 'TELEPHONE_WILLS',
  PROBATE = 'PROBATE',
  FUNERAL = 'FUNERAL',
  FUNERAL_PLAN = 'FUNERAL_PLAN',
}

export enum SPECIALISTS {
  PROBATE = 'PROBATE',
  FUNERAL = 'FUNERAL',
  FUNERAL_PLAN = 'FUNERAL_PLAN',
  WILLS = 'WILLS',
  TELEPHONE_WILLS = 'TELEPHONE_WILLS',
  LEGAL = 'LEGAL',
  FUNERAL_COORDINATORS = 'FUNERAL_COORDINATORS',
  FUNERAL_CELEBRANTS = 'FUNERAL_CELEBRANTS',
  FUNERAL_AWARDS = 'FUNERAL_AWARDS',
  CROSS_TEAM = 'CROSS_TEAM',
  PARTNERSHIPS_ACCOUNT_MANAGERS = 'PARTNERSHIPS_ACCOUNT_MANAGERS',
}

// Deprecated: please use PRODUCTS enum instead
export const WILLS = 'WILLS'
export const TELEPHONE_WILLS = 'TELEPHONE_WILLS'
export const PROBATE = 'PROBATE'
export const FUNERAL = 'FUNERAL'
export const FUNERAL_PLAN = 'FUNERAL_PLAN'

type ProductConfiguration = {
  id: PRODUCTS
  title: string
  description?: string
  ctaText?: string
  heroCtaText?: string
  illustration?: string
  illustrationAspectRatio?: number
  telephoneNumber: string
  funeralsSupportEmail?: string
  canRequestCallback?: boolean
  backstageEnum: string
  callbackLink?: string
  fwlsDisclaimerCopy?: string
}

const WILLS_PRODUCT: ProductConfiguration = {
  id: PRODUCTS.WILLS,
  title: 'Wills',
  description:
    'Write your will from the comfort of your own home in just 15 minutes.',
  ctaText: 'Write my will',
  illustration: 'blob-signing-will',
  illustrationAspectRatio: 233 / 250,
  telephoneNumber: WILLS_TELEPHONE_NUMBER,
  canRequestCallback: false,
  backstageEnum: 'will',
  callbackLink: TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
  fwlsDisclaimerCopy:
    'Farewill is not a law firm and is not regulated by the Solicitors Regulation Authority (SRA). While we can provide you with guidance, we do not provide bespoke advice, including tax advice.',
}

const TELEPHONE_WILLS_PRODUCT: ProductConfiguration = {
  id: PRODUCTS.TELEPHONE_WILLS,
  title: 'Telephone Wills',
  telephoneNumber: TELEPHONE_WILLS_TELEPHONE_NUMBER,
  backstageEnum: 'will',
}

const PROBATE_PRODUCT: ProductConfiguration = {
  id: PRODUCTS.PROBATE,
  title: 'Probate',
  description: `Get fixed-fee probate without the hassle from just ${formatPriceInPounds(
    PROBATE_SIMPLE_FEE
  )}.`,
  ctaText: 'Apply for probate',
  illustration: 'estate-admin-house-paperwork',
  illustrationAspectRatio: 513 / 420,
  telephoneNumber: PROBATE_TELEPHONE_NUMBER,
  canRequestCallback: false,
  backstageEnum: 'probate',
  fwlsDisclaimerCopy:
    'Farewill Ltd is not a law firm and is not regulated by the Solicitors Regulation Authority (SRA). We work with a third party SRA regulated law firm which is not a member of the Farewill group to deliver our probate services.',
}

const FUNERAL_PRODUCT: ProductConfiguration = {
  id: PRODUCTS.FUNERAL,
  title: 'Funerals',
  description: 'Arrange a direct cremation now or buy a pre-paid plan',
  ctaText: 'Find out about funerals',
  heroCtaText: 'Arrange a funeral',
  illustration: 'blob-urn-family-sunset',
  illustrationAspectRatio: 544 / 332,
  telephoneNumber: CREMATION_TELEPHONE_NUMBER,
  funeralsSupportEmail: FUNERALS_SUPPORT_EMAIL,
  canRequestCallback: true,
  backstageEnum: 'funeral',
}

const FUNERAL_PLAN_PRODUCT: ProductConfiguration = {
  id: PRODUCTS.FUNERAL_PLAN,
  title: 'Funeral Plans',
  ctaText: 'Buy a funeral plan',
  backstageEnum: 'funeral_plan',
  telephoneNumber: FUNERAL_PLAN_TELEPHONE_NUMBER,
  canRequestCallback: false,
}

export const PRODUCTS_MAP = {
  [PRODUCTS.WILLS]: WILLS_PRODUCT,
  [PRODUCTS.TELEPHONE_WILLS]: TELEPHONE_WILLS_PRODUCT,
  [PRODUCTS.PROBATE]: PROBATE_PRODUCT,
  [PRODUCTS.FUNERAL]: FUNERAL_PRODUCT,
  [PRODUCTS.FUNERAL_PLAN]: FUNERAL_PLAN_PRODUCT,
}

export const PRODUCTS_VIEWS_FOLDERS_MAPPING = {
  [PRODUCTS.FUNERAL]: VIEWS_FOLDER_KEYS[VIEWS_FOLDER.CREMATION],
  [PRODUCTS.FUNERAL_PLAN]: VIEWS_FOLDER_KEYS[VIEWS_FOLDER.CREMATION],
  [PRODUCTS.PROBATE]: VIEWS_FOLDER_KEYS[VIEWS_FOLDER.PROBATE],
  [PRODUCTS.TELEPHONE_WILLS]: VIEWS_FOLDER_KEYS[VIEWS_FOLDER.WILLS],
  [PRODUCTS.WILLS]: VIEWS_FOLDER_KEYS[VIEWS_FOLDER.WILLS],
}

export const LIVE_PRODUCTS = [
  PRODUCTS.WILLS,
  PRODUCTS.TELEPHONE_WILLS,
  PRODUCTS.PROBATE,
  PRODUCTS.FUNERAL,
]

export const CORE_PRODUCTS: [
  PRODUCTS.WILLS,
  PRODUCTS.PROBATE,
  PRODUCTS.FUNERAL
] = [PRODUCTS.WILLS, PRODUCTS.PROBATE, PRODUCTS.FUNERAL]
